.logo{
    display: flex;
    padding: 15px;

}
.divbtnIntMobile{
    display: flex;
    flex-direction: column;
}
.mobilegrtVtn{
/* padding: 19px; */
}
.logoImg{
    margin: 0 auto;
}
.divMobileBtn{
    /* padding: 10px; */
}
.serv3{
    text-align:center;
    letter-spacing: 0;
color: #0066B5;
font-family: Montserrat;
font-weight: 400;
font-size: 20px;
}
.divTitreVideo{
    max-width: 1330px;
    margin: 0 auto;
    display: flex;
    padding-top: 1%;
    padding-bottom: 1%;
}
.divVideo{
    flex-direction: column;
    cursor: pointer;
}

.serv3Mob{
    text-align:center;
    letter-spacing: 0;
color: #0066B5;
font-family: cairo;
font-weight: 400;
font-size: 20px;
}
.serv1{
/* text-align:center; */
letter-spacing: 0;
color: #7F7F7F;
font-family: Montserrat;
font-weight: 400;
font-size: 20px;
}
.serv1Mob{
    /* text-align:center; */
    letter-spacing: 0;
    color: #7F7F7F;
    font-family: cairo;
    font-weight: 400;
    font-size: 20px;
    }
.footer{
    background: #0066B5 0% 0% no-repeat padding-box;
opacity: 1;
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 1%;
}
.serv3-1{
letter-spacing: 0;
color: #0066B5;
font-family: Montserrat;
font-weight: 400;
font-size: 17px;
}
.titreFooter{
letter-spacing: 0;
color: #FFFFFF;
opacity: 1;
font-family: Montserrat;
font-weight: lighter;
font-size: 11px;
text-align: center;
padding: 4px;
}
.btnarabe{
font-family: Montserrat;
font-weight: 600;
font-size: 14px;
letter-spacing: 0;
color: #FFFFFF;
text-transform: capitalize;
opacity: 1;
background: #0066B5 0% 0% no-repeat padding-box;
border-radius: 6px;
opacity: 1;
border:0px solid;
cursor: pointer;


}
.btnFr{
    font-family: cairo;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    background: #0066B5 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    border:0px solid;
    cursor: pointer;

    
    }
.divBanner{
    display: flex;
}
.imgBanner{
    margin: 0 auto;

}
.divTitre{
    display: flex;
    padding: 1%;
    flex-direction: column;

}
.titre{
    /* margin: 0 auto; */
letter-spacing: 0;
color: #0066B5;
text-transform: capitalize;
opacity: 1;
font-family: Montserrat;
font-weight: 600;
font-size: 23px;
}
.titreAr{
    /* margin: 0 auto; */
letter-spacing: 0;
color: #0066B5;
text-transform: capitalize;
opacity: 1;
font-family: cairo;
font-weight: 600;
font-size: 23px;
}
.divTitre2{
    display: flex;
    padding-bottom: 1%;
    justify-content: center;
}
.promo{
    display: flex;
    padding-left: 135px;
    padding-right: 110px;

}
.prompColumn{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    max-width: 1330px;
    margin: 0 auto;
}
.promoRow{
    flex-direction: column;
    width: 25%;
    /* padding-left: 73px;
    padding-right: 73px; */
}
.titreComposant{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 0;
    color: #0066B5;
    text-transform: capitalize;
    opacity: 1;
}
.titreComposantAr{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 0;
    color: #0066B5;
    text-transform: capitalize;
    opacity: 1;


}

.titreComposant2{
    display: none;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 0;
    color: #0066B5;
    text-transform: capitalize;
    opacity: 1;
}
.paragComposant{
font-family: Montserrat;
font-weight: 400;
font-size: 17px;
letter-spacing: 0;
color: #7F7F7F;
opacity: 1;
height: 153px;
padding-right: 15px;
line-height: 24px;

}
.paragComposantMob{
    font-family: cairo;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    color: #7F7F7F;
    opacity: 1;
    height: 142px;
    padding-right: 9px;
    padding-left: 25px;

    
    }
.parag2Composant{
  
font-family: Montserrat;
font-weight: bold;
font-size: 17px;
letter-spacing: 0;
color: #0066B5;
opacity: 1;
padding-right: 5px;
padding-left: 5px;

}
.parag2ComposantMob{
  
    font-family: cairo;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 0;
    color: #0066B5;
    opacity: 1;
    padding-right: 5px;
    padding-left: 5px;
    
    }
.btn{
    background: #FF9D00 0% 0% no-repeat padding-box;
border-radius: 19px;
opacity: 1;
width: 95px;
height: 37px;
letter-spacing: 0;
color: #FFFFFF;
opacity: 1;
font-family: Montserrat;
font-weight: bold;
font-size: 20px;
border: 0px solid;
direction: ltr;

}
.divSlider{
    width: 100% !important;
    margin: 0 auto !important;
}
.MuiDialog-paperWidthSm{
    max-width: 1330px !important;
    max-height: 1300px !important;

}
.MuiDialogContent-root{
    background-color: rgb(0, 0, 0) !important;
}
.MuiPaper-root {
    background-color : rgb(0, 0, 0) !important;
}
@media only screen and (max-width: 768px) {
    .withIframe{
        width: 100% !important;
    }
    .MuiDialogContent-root >div {
        width: 100% !important;
      height: 100% !important;
}

    .MuiDialogContent-root{
        background-color: rgb(0, 0, 0) !important;
    }
    .divVideo{
        text-align: center;
        cursor: pointer;
    }
    .divTitreVideo{
        max-width: unset;
        margin: unset;
        display: unset;
    }
    /* .dialogVideo{
        width: 100% !important;
    height: 100% !important;

    } */
    
    .serv3-1{
        letter-spacing: 0;
        color: #0066B5;
        font-family: Montserrat;
        font-weight: 400;
        font-size: 11px;
        }
    .imgmobiledes{
        width: 53px;
     height: 17px;
    padding-top: 11px;

    }
    .imgmobiledess{
        width: 53px;
height: 17px;

    }
    .divMobileBtn2{
        padding: 16px;
    }
    .divbtnIntMobile{
        display: flex;
     flex-direction: row;
     margin-top: -6%;
    }
    .divTitre2{
        display: flex;
        padding:5px;
        justify-content: unset;
    }
    .divTitre{
        padding: 3px;
    }
    .serv1{
        text-align:unset;
        letter-spacing: 0;
        color: #7F7F7F;
        font-family: Montserrat;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        }
        .serv1Mob{
            text-align:unset;
            letter-spacing: 0;
            color: #7F7F7F;
            font-family: cairo;
            font-weight: 400;
            font-size: 14px;
        }
        .serv3{
        text-align:unset;
        letter-spacing: 0;
        color: #0066B5;
        font-family: Montserrat;
        font-weight: 400;
        font-size: 14px;
        }
        .serv3Mob{
            text-align:unset;
            letter-spacing: 0;
            color: #0066B5;
            font-family: cairo;
            font-weight: 400;
            font-size: 14px;
            }

    .prompColumn{
        flex-direction: column;
        display: flex;
        padding-left: unset;
        padding-right: unset;
    }
    .promoRow{
        flex-direction: row;
        width: 100%;
        display: flex;
        padding-left: unset;
        padding-right: unset;
    }
    .promo{
        padding-left: unset;
        padding-right: unset; 
    }
    .titreComposant2{
        display: block;
       
    }
    
    .img{
      height: 130px;
    
    }
    .imgMobile{
        display: flex;
        flex-direction: column;
        padding-top:21px;
        width: 40%;
        padding: 3%;

    }
    .paragMobile{
        /* display: flex;
        flex-direction: column; */
        /* padding: 10px; */
        width: 60%;
        padding-left: 10px;


    }
    .titreComposant{
     
        font-family: Montserrat;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0;
        color: #FF9D00;
        text-transform: capitalize;
        opacity: 1;
    }
    .titreComposantAr{
        font-family: Montserrat;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0;
        color: #FF9D00;
        text-transform: capitalize;
        opacity: 1;
        padding-right: 17px;


    }
    .paragComposant{
        font-family: Montserrat;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0;
        color: #7F7F7F;
        opacity: 1;
        padding-right: 15px;
        line-height: 19px;
        /* width: unset;
        height: unset; */
        /* text-align: justify; */
    
        }
        .paragComposantMob{
            font-family: cairo;
            font-weight: 400;
            font-size: 11px;
            letter-spacing: 0;
            color: #7F7F7F;
            opacity: 1;
            padding-right: 15px;
            /* width: unset;
            height: unset; */
        
            }
       
        .titre{
            margin: unset;
            font-family: Montserrat;
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 0;
            color: #0066B5;
            text-transform: capitalize;
           opacity: 1;
        }
        .titreAr{
            margin: unset;
            font-family: cairo;
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 0;
            color: #0066B5;
            text-transform: capitalize;
           opacity: 1;
        }
        .parag2Composant{
  
            font-family: Montserrat;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0;
            color: #0066B5;
            opacity: 1;

            
            }
            .parag2ComposantMob{
  
                font-family: cairo;
                font-weight: bold;
                font-size: 12px;
                letter-spacing: 0;
                color: #0066B5;
                opacity: 1;
                padding-right: 16px;

    
                
                }
            .mobilegrtVtn{
                display: flex;
                flex-direction: row;
                padding-top:6px;
                margin-top: -7%;
            }
            .mobilegrtVtn22{
                display: flex;
                flex-direction: row;
                padding: unset;
            }
            .mobilegrtVtn2
            {
            display: flex;
            flex-direction: row;
            padding-top:28px;
            margin-top: -12%;
            }
                .btn{
                    background: #FF9D00 0% 0% no-repeat padding-box;
                border-radius: 19px;
                opacity: 1;
                              letter-spacing: 0;
                color: #FFFFFF;
                opacity: 1;
                font-family: Montserrat;
                font-weight: bold;
                font-size: 13px;
                border: 0px solid;
                width: 68px;
                height: 27px;
 
            }
            .divMobileBtn{
                /* padding: 10px; */
            }
            .imgBanner{
                width: 100%;
            }
}
@media screen and (min-width: 993px) and (max-width: 1280px) { 


.promoRow{
    flex-direction: column;
    width: 33.33%;
    padding-left: unset;
    padding-right: unset;

}
.prompColumn {
    flex-wrap: wrap;
    padding-left: unset;
    padding-right: unset;
    /* padding-right: 70px;
    padding-left: 70px; */

}
.imgBanner {
    margin: 0 auto;
    width: 100%;
}
}
@media screen and (min-width: 796px) and (max-width: 992px) { 
    .promoRow{
        flex-direction: column;
        width: 50%;
        padding-left: unset;
        padding-right: unset;
    
    }
    .prompColumn {
        flex-wrap: wrap;
        padding-left: 50px;
       padding-right: 50px;

    
    }
    .imgBanner {
        margin: 0 auto;
        width: 100%;
    }
    .divMobileBtn{
        /* padding: 10px; */
    }

}
